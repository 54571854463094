import { NavLink } from '@remix-run/react'

import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { BiLogOut } from 'react-icons/bi'
import { HiOutlineUsers } from 'react-icons/hi2'
import { IoHomeOutline } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import globalState from '~/lib/state'
import LadderIcon from '~/media/LadderIcon'

import ProfileIcon from '~/media/ProfileIcon'
import ReportIcon from '~/media/ReportIcon'

import whatsapp from '~/media/whatsapp-icon.svg'
import logout from '~/utils/logout'

export default function Navbar() {
    const auth = globalState.getState().accessToken

    const { t } = useTranslation()
    return (
        <>
            {auth && (
                <footer
                    className={twMerge(
                        'fixed bottom-0 left-0 right-0 z-[100] flex items-center justify-around border-t border-grey-input bg-white py-3 md:top-0 md:h-full md:flex-col md:justify-start md:gap-10 md:px-2 md:pt-8 md:shadow-xl',
                        i18next.language === 'he'
                            ? 'md:left-auto'
                            : 'md:right-auto'
                    )}
                >
                    <NavLink
                        className="flex w-2/12 flex-col items-center justify-start text-12"
                        to="/"
                    >
                        {' '}
                        <IoHomeOutline className="h-6 w-6" />
                        {t('Home')}
                    </NavLink>
                    <NavLink className="flex w-2/12 flex-col items-center justify-start text-12">
                        {' '}
                        <LadderIcon />
                        {t('Reports')}
                    </NavLink>{' '}
                    <NavLink
                        className="flex w-2/12 flex-col items-center justify-start break-words text-center text-12"
                        to="/pool_operators"
                    >
                        {' '}
                        <HiOutlineUsers className="h-6 w-6" />
                        {t('Pool operators')}
                    </NavLink>{' '}
                    <NavLink
                        className="flex w-2/12 flex-col items-center justify-start text-12"
                        to="/my_pools"
                    >
                        {' '}
                        <ReportIcon />
                        {t('Pools')}
                    </NavLink>
                    <NavLink
                        to="/profile"
                        className="flex w-2/12 flex-col items-center justify-start text-12"
                    >
                        <ProfileIcon />
                        {t('Profile')}
                    </NavLink>
                    <button
                        onClick={() => {
                            window.open('https://wa.me/972509863878', '_blank')
                        }}
                        className="mx-auto hidden h-10 w-10 flex-col items-center justify-center gap-2 text-12 md:mt-14 md:flex"
                    >
                        <img src={whatsapp} alt="whatsapp icon" />
                        {t('Whatsapp support')}
                    </button>
                    <button
                        onClick={logout}
                        className="flex flex-col items-center gap-2 md:pt-4"
                    >
                        <BiLogOut className="h-6 w-6" />
                        <p className="text-xs">{t('logout')}</p>
                    </button>
                </footer>
            )}
        </>
    )
}
