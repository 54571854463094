import { Capacitor } from '@capacitor/core'
import { SafeArea } from 'capacitor-plugin-safe-area'

export const createSafeAreas = async () => {
    const screenState = { top: 0, bottom: 0 }
    const { insets } = await SafeArea.getSafeAreaInsets()
    if (Capacitor.getPlatform() === 'ios') {
        screenState.bottom = Math.round(insets.bottom / 2)
        screenState.top = Math.round(insets.top * 0.9)
    } else {
        screenState.bottom = 0
        screenState.top = 0
    }
    return screenState
}
